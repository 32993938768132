import axios from "axios";

const window = global.window;
let baseURL;

if (typeof window === "undefined") {
    baseURL = "https://beonecanadawebapi.azurewebsites.net/api/"
} else if (window.location.href.includes("testhp2021") || window.location.href.includes("localhost")) {
    baseURL = "https://beonecanadawebapi.azurewebsites.net/api/"
} else {
    baseURL = "https://beonecanadawebapi.azurewebsites.net/api/"
}

const instance = axios.create({
  baseURL: baseURL,
  timeout: 60000,
  headers: {},
});

instance.interceptors.request.use(async (config) => {
  try {
    config.headers["client"] = "alpha";
    config.headers["apitoken"] = process.env.GATSBY_BOX_ONE_API_TOKEN;
    return config;
  } catch (e) {
    console.error(e);
  }
});

instance.interceptors.response.use(function (response) {
  // window.localStorage.removeItem("serverError");
  return response;
}, function (error) {
  if (typeof window !== "undefined" && (window.location.href.includes("plans") || window.location.href.includes("activation"))) {
    console.log("ERROR DETECTED : ", error);
    // window.localStorage.setItem("serverError", "403");
  } else if (error.response.status === 429) {
    alert(error.response.data);
    // window.localStorage.setItem("serverError", "429");
  } else {
    // window.localStorage.removeItem("serverError");
    console.log("no error detected");
  }
})

export default instance;
